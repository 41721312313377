<template>
    <div></div>
</template>

<script>
    import api from "@/services/api";

    export default {
        props: {
            code: {
                required: true
            }
        },
        mounted () {
            if(this.$store.state.isSignedIn)
            {
                // Previously, we allowed sign in via OTP even when already signed in. 
                // However, due to multiple router-view components on App.vue we had the SignInOTP component mounting multiple times and preventing OTP sign in
                // this.$store.dispatch('signOut')
                // .then(() => {
                //     this.requestCookie();
                // });
            }
            else
            {
                this.requestCookie();
            }
        },
        methods: {
            requestCookie: function()
            {
                api
                .get('/sanctum/csrf-cookie')
                .then(() => {
                    this.requestSignIn();
                })
                .catch(() => {
                    this.$router.replace('/');
                });
            },
            requestSignIn: function() {
                api
                .post('/api/v1/login', {
                    login_token: this.code,
                })
                .then(() => {
                    this.storeSignIn();
                })
                .catch(() => {
                    this.$router.replace('/');
                });
            },
            storeSignIn: function()
            {
                this.$store.dispatch('signIn')
                .then(() => {
                    this.checkPractices();                   
                });
            },
            checkPractices: function()
            {
                this.$store.dispatch('fetchPractices')
                .then((practices) => {
                    if(practices.length === 1)
                    {
                        this.$store.dispatch('setCurrentPracticeId', practices[0].id);
                        this.$store.dispatch('setCurrentPractice');
                    }
                })
                .finally(() => {
                    this.$router.replace('/');
                })
            },
        }
    }
</script>